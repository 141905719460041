import { useCallback, useEffect, useState } from "react";
import { getJSON } from "../shared/http.helper";
import { Ticket } from "./ticket.types";

export const useTickets = (batchId?: number) => {
  const [tickets, setTickets] = useState<Ticket[]>();

  const fetchTickets = useCallback(() => {
    getJSON("/ticket" + (batchId ? '/' + batchId : '')).then((response) => {
      setTickets(response.data.tickets);
    });
  }, [batchId]);

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  return { tickets, fetchTickets };
};
