import { AxiosResponse } from "axios";
import { postJSON } from "../shared/http.helper";
import { CreateBatchRequest, CreateBatchResponse } from "./batch.types";

const URL_PREFIX = "/batch";

export const createBatchRequest = (request: CreateBatchRequest): Promise<AxiosResponse<CreateBatchResponse>> => {
  return postJSON(`${URL_PREFIX}`, request);
};

// export const updateCompany = (id: number, request: UpdateCompanyRequest) => {
//   return putJSON(`${URL_PREFIX}/${id}`, request);
// };

// export const getCompany = (id: number): Promise<AxiosResponse<Company>> => getJSON(`${URL_PREFIX}/${id}`);

// export const companyEmploymentsRequest = (request: CompanyEmploymentsRequest): Promise<AxiosResponse<CompanyEmploymentsResponse>> => {
//   return postJSON(`${URL_PREFIX}/employments`, request);
// };
