import { useEffect } from 'react';
import { Box, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import QRCode from 'qrcode';
import { useTickets } from '../ticket.hooks';
import { b64toBlob } from '../../shared/shared.helpers';
import { AppNavigation } from '../../shared/components/app-navigation';

export const Tickets = () => {
  const { tickets, fetchTickets } = useTickets();

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  const handleViewQR = async (code: string) => {
    const partToReplace = 'data:image/png;base64,';
    const qrCode = await QRCode.toDataURL(code);
    const blob = b64toBlob(`${qrCode.substring(partToReplace.length, Infinity)}`, 'image/png');
    const url = URL.createObjectURL(blob);
    window.open(url);
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);
  };

  return (
    <>
      <AppNavigation />
      <Box m={10}>
        <Heading size='md'>Tickets</Heading>

        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th isNumeric>ID</Th>
                <Th>Batch</Th>
                <Th>Code</Th>
                <Th>QR Code</Th>
                <Th isNumeric>Winning</Th>
              </Tr>
            </Thead>
            <Tbody>
              {tickets?.map((ticket) => {
                return (
                  <Tr key={ticket.id}>
                    <Td isNumeric>{ticket.id}</Td>
                    <Td>{ticket.batch.label}</Td>
                    <Td>{ticket.code}</Td>
                    <Td><Text cursor={'pointer'} onClick={() => handleViewQR(ticket.code)} color="blue">View QR Code</Text></Td>
                    <Td isNumeric color={ticket.winning ? 'green' : 'red'}>{ticket.winning ? "Winning" : "Not winning"}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
