import { useCallback, useEffect, useState } from "react";
import { getJSON } from "../shared/http.helper";
import { Prize } from "./prize.types";

export const usePrizes = () => {
  const [prizes, setPrizes] = useState<Prize[]>();

  const fetchPrizes = useCallback(() => {
    getJSON("/prize").then((response) => {
      setPrizes(response.data.prizes);
    });
  }, []);

  useEffect(() => {
    fetchPrizes();
  }, [fetchPrizes]);

  return { prizes, fetchPrizes };
};
