import { useParams } from 'react-router-dom';
import { useTickets } from '../../ticket/ticket.hooks';
import { QRCodeSVG } from 'qrcode.react';

export const BatchDocument = () => {
  const { batchId } = useParams() as { batchId: string };
  const { tickets } = useTickets(Number(batchId));

  return (
    <div>
      {tickets?.map((ticket) => {
        return (
          <div key={ticket.id} style={{ float: 'left', padding: '10px', border: '1px solid #ccc' }}>
            <QRCodeSVG
              value={`https://zolotaya-nesushka.ru/vt/${ticket.code}`}
              size={180}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"L"}
              includeMargin={false}
            />
          </div>
        );
      })}
    </div>
  );
};
