import { FC } from "react";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { createBatchRequest } from "../batch.requests";
import { Prize } from "../../prize/prize.types";

type Props = {
  isOpen: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  prizes: Prize[];
};

export const BatchModal: FC<Props> = ({
  isOpen,
  onOpen,
  onClose,
  prizes,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    createBatchRequest({
      label: data.label,
      winRate: data.winRate,
      prizeId: data.prizeId,
      quantity: data.quantity,
    }).then(() => {
      onClose();
    }).catch(() => {

    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Prize</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl isRequired>
              <FormLabel htmlFor='label'>Label</FormLabel>
              <Input id='label' type='text' {...register('label', { required: true })} />
              {errors.label && <FormErrorMessage>Label is required.</FormErrorMessage>}
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor='quantity'>Quantity</FormLabel>
              <Input id='quantity' type='number' {...register('quantity', { required: true, pattern: /\d+/ })} />
              {errors.quantity && <FormErrorMessage>Quantity is required.</FormErrorMessage>}
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor='winRate'>Win Rate</FormLabel>
              <Input id='winRate' type='number' {...register('winRate', { required: true, pattern: /\d+/ })} />
              {errors.winRate && <FormErrorMessage>Win Rate is required.</FormErrorMessage>}
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor='prizeId'>Prize</FormLabel>
              <Select placeholder='Select prize' {...register('prizeId', { required: true })} id='prizeId'>
                {prizes.map((prize) => (
                  <option key={prize.id} value={prize.id}>{prize.label}</option>
                ))}
              </Select>
              {errors.prizeId && <FormErrorMessage>Prize is required.</FormErrorMessage>}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='gray' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button type="submit" colorScheme="blue">Save</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
