import { matchPath, NavLink, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Container } from '@chakra-ui/react';

export const AppNavigation = () => {
  const location = useLocation();

  const dashboardColor = matchPath('/', location.pathname) ? 'blue' : undefined;
  const ticketsColor = matchPath('/tickets', location.pathname) ? 'blue' : undefined;

  return (
    <Container maxWidth="1280px" position={'sticky'} top={0} backgroundColor={'white'} boxShadow={'md'}>
      <Breadcrumb h={14} flex={'1'} display={'flex'} alignItems={'center'} separator={'•'}>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/" color={dashboardColor}>Dashboard</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={NavLink} to="/tickets" color={ticketsColor}>Tickets</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </Container>
  );
};
