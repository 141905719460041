import { FC } from "react";
import { Button, FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputRightAddon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { createPrizeRequest } from "../prize.requests";

type Props = {
  isOpen: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
};

export const PrizeModal: FC<Props> = ({
  isOpen,
  onOpen,
  onClose,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data: any) => {
    createPrizeRequest({ amount: data.amount, label: data.label }).then(() => {
      onClose();
    }).catch(() => {

    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Prize</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormControl isRequired>
              <FormLabel htmlFor='label'>Label</FormLabel>
              <Input id='label' type='text' {...register('label', { required: true })} />
              {errors.label && <FormErrorMessage>Label is required.</FormErrorMessage>}
            </FormControl>
            <FormControl isRequired>
              <FormLabel htmlFor='amount'>Amount</FormLabel>
              <InputGroup>
                <Input id='amount' type='number' {...register('amount', { required: true, pattern: /\d+/ })} />
                <InputRightAddon children='RUB' />
              </InputGroup>
              {errors.amount && <FormErrorMessage>Amount is required.</FormErrorMessage>}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='gray' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button type="submit" colorScheme="blue">Save</Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
