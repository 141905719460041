import { useCallback, useEffect, useState } from "react";
import { getJSON } from "../shared/http.helper";
import { Batch } from "./batch.types";

export const useBatches = () => {
  const [batches, setBatches] = useState<Batch[]>();

  const fetchBatches = useCallback(() => {
    getJSON("/batch").then((response) => {
      setBatches(response.data.batches);
    });
  }, []);

  useEffect(() => {
    fetchBatches();
  }, [fetchBatches]);

  return { batches, fetchBatches };
};
