import axios from "axios";
import { parse } from "qs";
import { getWithExpiry } from './local-storage-with-expiry';

type HttpOptions = {
  withAuth?: boolean;
};

const API_URL = window.location.protocol.startsWith('https') ? 'https://api.zolotaya-nesushka.ru' : 'http://localhost:8082';

const instance = axios.create({
  baseURL: API_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(function (config) {
  if (!config.headers?.IgnoreAuth) {
    const token = getWithExpiry("token");
    config.headers!.Authorization = `Bearer ${token}`;
  }
  delete config.headers!.IgnoreAuth;
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    localStorage.removeItem('token');
    window.location.reload();
  }

  // Do something with request error
  return Promise.reject(error);
});

const headerConductor = (withAuth?: boolean) => {
  const headers = {} as any;

  if (!withAuth) {
    headers.IgnoreAuth = true;
  }

  return headers;
};

export const postJSON = <T>(route: string, payload: object, options?: HttpOptions) => {
  const headers = headerConductor(options?.withAuth ?? true);
  return instance.post<any, T>(route, payload, { headers });
};

export const putJSON = (route: string, payload?: object | string) => {
  return instance.put(route, payload);
};

export const patchJSON = (route: string, payload: object) => {
  return instance.patch(route, payload);
};

export const deleteJSON = (route: string, payload?: object) => {
  return instance.delete(route, payload);
};

export const getJSON = (route: string, params?: string) => {
  return instance.get(route, params ? parse(params) : undefined);
};
