import { AxiosResponse } from "axios";
import { postJSON } from "../shared/http.helper";
import { CreatePrizeRequest, CreatePrizeResponse } from "./prize.types";

const URL_PREFIX = "/prize";

export const createPrizeRequest = (request: CreatePrizeRequest): Promise<AxiosResponse<CreatePrizeResponse>> => {
  return postJSON(`${URL_PREFIX}`, request);
};

// export const updateCompany = (id: number, request: UpdateCompanyRequest) => {
//   return putJSON(`${URL_PREFIX}/${id}`, request);
// };

// export const getCompany = (id: number): Promise<AxiosResponse<Company>> => getJSON(`${URL_PREFIX}/${id}`);

// export const companyEmploymentsRequest = (request: CompanyEmploymentsRequest): Promise<AxiosResponse<CompanyEmploymentsResponse>> => {
//   return postJSON(`${URL_PREFIX}/employments`, request);
// };
