import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Dashboard } from './dashboard/components/dashboard';
import { BatchDocument } from './batch/components/batch-document';
import { Tickets } from './ticket/components/tickets';
import { getWithExpiry, setWithExpiry } from './shared/local-storage-with-expiry';

function App() {
  const [bootstrapped, setBootstrapped] = useState(false);
  useEffect(() => {
    const token = getWithExpiry('token');
    if (!token) {
      const expirationPeriod = 7 * 24 * 60 * 60 * 1000; // 7 days
      setWithExpiry('token', prompt("Enter auth key!")!, expirationPeriod);
      setBootstrapped(true);
    } else {
      setBootstrapped(true);
    }
  }, []);

  if (!bootstrapped) {
    return null;
  }

  return (
    <div>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/batch/:batchId/document" element={<BatchDocument />} />
        <Route path="/tickets" element={<Tickets />} />
        {/* <Route path="about" element={<About />} /> */}
      </Routes>
    </div>
  );
}

export default App;
