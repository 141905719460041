import { usePrizes } from "../../prize/prize.hooks";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  HStack,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useBatches } from "../../batch/batch.hooks";
import { PrizeModal } from "../../prize/components/prize-modal";
import { BatchModal } from "../../batch/components/batch-modal";
import { AppNavigation } from '../../shared/components/app-navigation';

export const Dashboard = () => {
  const { prizes, fetchPrizes } = usePrizes();
  const { batches, fetchBatches } = useBatches();

  const prizeDisclosure = useDisclosure();
  const batchDisclosure = useDisclosure();

  return (
    <>
      <AppNavigation />
      <Container maxWidth="1280px">
        <HStack spacing='36px' justifyContent={"start"} alignItems="start" mt={10}>
          <Box w={"30%"}>
            <Flex alignItems={"center"}>
              <Box flex={"1"}>
                <Heading size='md'>Prizes</Heading>
              </Box>
              <Button colorScheme={"blue"} variant="ghost" size={"sm"} onClick={prizeDisclosure.onOpen}>+ Prize</Button>
              <PrizeModal {...prizeDisclosure} onClose={() => {
                prizeDisclosure.onClose();
                fetchPrizes();
              }} />
            </Flex>

            <TableContainer>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th isNumeric>ID</Th>
                    <Th>Label</Th>
                    <Th isNumeric>Amount</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {prizes?.map((prize) => {
                    return (
                      <Tr key={prize.id}>
                        <Td isNumeric>{prize.id}</Td>
                        <Td>{prize.label}</Td>
                        <Td isNumeric>{prize.amount}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Box w={"70%"}>
            <Flex alignItems={"center"}>
              <Box flex={"1"}>
                <Heading size='md'>Batches</Heading>
              </Box>
              <Button colorScheme={"blue"} variant="ghost" size={"sm"} onClick={batchDisclosure.onOpen}>+ Batch</Button>
              {!!prizes && (
                <BatchModal {...batchDisclosure} prizes={prizes} onClose={() => {
                  batchDisclosure.onClose();
                  fetchBatches();
                }} />
              )}
            </Flex>

            <TableContainer>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th isNumeric>ID</Th>
                    <Th>Label</Th>
                    <Th isNumeric>Quantity</Th>
                    <Th isNumeric>Win Rate</Th>
                    <Th>Prize</Th>
                  </Tr>
                </Thead>
                <Tbody overflowY={"hidden"} height="100px">
                  {batches?.map((batch) => {
                    return (
                      <Tr key={batch.id}>
                        <Td isNumeric>{batch.id}</Td>
                        <Td isNumeric>{batch.quantity}</Td>
                        <Td>{batch.label}</Td>
                        <Td isNumeric>{batch.winRate}</Td>
                        <Td>{batch.prize.label}</Td>
                        <Td><Link href={`/batch/${batch.id}/document`} target='_blank' color={'blue'}>Document</Link></Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </HStack>
      </Container>
    </>
  );
};
